import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Snackbar } from "@material-ui/core";

export default () => {
  const aisData = useStoreState((state) => state.aisData.features);
  const getAisFeatures = useStoreActions((actions) => actions.aisData.getFeatures);
  const currentAnalysisRunId = useStoreState(
    (state) => state.AnalysisRuns.currentAnalysisRun.analysis_run_id
  );

  const [notificationString, setNotificationString] = useState(null)

  useEffect(() => {
    setNotificationString(`${aisData.length} AIS features loaded!`)
  }, [aisData])

  useEffect(() => {
    getAisFeatures({ analysisRunUUID: currentAnalysisRunId })
  }, [])


  const Notification = () => (
    <Snackbar open={!!notificationString}
      onClose={() => setNotificationString(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      message={notificationString}
    >
    </Snackbar>
  )

  return <Notification />
}
