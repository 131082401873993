import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore } from 'easy-peasy';
import { AnalysisRuns } from './models/AnalysisRuns';
import { NewFindings } from './models/NewFindings';
import { findings } from './models/findings';
import { AisData } from './models/AisData';


const models = {
  AnalysisRuns: AnalysisRuns,
  findings: findings,
  NewFindings: NewFindings,
  aisData: AisData,
}

const store = createStore(models, {
  compose: composeWithDevTools({ realtime: true, trace: true })
});

export default store;
