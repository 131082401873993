import { action, thunk } from "easy-peasy";
import axios from "axios";
import { headers } from "./headers";
// import ais_sample from "../this_will_come_via_backend.json";

export const AisData = {
  features: [],

  setFeatures: action((state, payload) => {
    state.features = payload;
  }),

  getFeatures: thunk(async (actions, payload) => {
    try {
      const { data } = await axios.get(
        `${process.env.BACKEND_BASE_URL}/ais/${payload.analysisRunUUID}/`,
        headers()
      );
      console.log(data);
      actions.setFeatures(data)
    } catch (error) {
      // // fetch dummy data from sample file + simulate waiting time
      // const shuffled = ais_sample.sort(() => 0.5 - Math.random());
      // const randomRecords = shuffled.slice(0, 100);
      // setTimeout(() => actions.setFeatures(randomRecords), 1500)
      actions.setFeatures([])
      console.log(error.message);
    }
  })
};
