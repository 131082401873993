import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';
import store from './configureStore';
import AnalysisRunConnector from './components/AnalysisRunConnector';
import AnalysisRunIndex from './components/AnalysisRunIndex';
import Content from './components/content';
import Login from './components/Login';
import Logout from './components/Logout';
import Navbar from './components/Navbar';
import About from './components/about';
// import registerServiceWorker from './serviceWorker';
import './globalStyles.css';

Sentry.init({
  dsn: "https://6754872da89347c6bcd273c3baa3476e@sentry.dezim.space/8",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <div>
    <StoreProvider store={store}>
      <Router>
        <div>
          <Navbar />

          <Switch>
            <Route path="/about">
              <Content>
                <About />
              </Content>
            </Route>
            <Route path="/login">
              <Content>
                <Login />
              </Content>
            </Route>
            <Route path="/logout">
              <Content auth>
                <Logout />
              </Content>
            </Route>
            <Route path="/analysis_runs/:analysis_run_id">
              <Content auth withoutContainer>
                <AnalysisRunConnector />
              </Content>
            </Route>
            <Route path="/">
              <Content auth>
                <AnalysisRunIndex />
              </Content>
            </Route>
          </Switch>
        </div>
      </Router>
    </StoreProvider>
  </div>,
  document.getElementById("root")
);

// Learn more about service workers in CRA: http://bit.ly/CRA-PWA
// registerServiceWorker();
