import styles from './index.module.css'
import React from 'react';
import { Grid, Container } from '@material-ui/core';

export default ({ children, auth = false, withoutContainer = false }) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
    >
      <Grid item xs={12} md={8}>
        <Container className={styles.container}>
          {children}
        </Container>
      </Grid>
    </Grid>
  )
};
