import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import styles from './index.module.css';
import axios from 'axios';

export default() => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);

  const login = async(user) => {
    try {
      const { data } = await axios.post(
        `${process.env.BACKEND_BASE_URL}/auth/login/`, user
      );

      localStorage.clear();
      localStorage.setItem('token', data.key);
      window.location.replace(`${process.env.FRONTEND_BASE_PATH}`);
    } catch (error) {
      setPassword('');
      localStorage.clear();
      setErrors(true);
    }
  }

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      email: email,
      password: password
    };

    login(user);
  };

  return (
    <div className={styles.container}>
      <h1>Login</h1>
      {errors === true && <h2>Cannot log in with provided credentials</h2>}
      <form onSubmit={onSubmit}>
        <TextField
          label='Email' name='email' type='email'
          value={email}
          required
          onChange={e => setEmail(e.target.value)}
        />
        <br />
        <TextField
          label='Password' name='password' type='password'
          value={password}
          required
          onChange={e => setPassword(e.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" type="submit" name="Submit">Login</Button>
      </form>
    </div>
  );
};
