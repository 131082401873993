import styles from "./findingAccordion.module.css";
import React from "react";
import { useStoreActions } from "easy-peasy";
import { AccordionActions } from "@material-ui/core";
import {
  Tooltip,
  Button,
  Grid,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { labelColorMapping } from "../../../support/colors";

export default ({ finding }) => {
  const updateFindingDetails = useStoreActions(
    (actions) => actions.findings.updateFindingDetails
  );

  const resetFinding = () => {
    formData.label = null;

    updateFindingDetails({
      ...formData,
      label: null,
    });
  };

  const [formData] = React.useState({
    finding_id: finding.finding_id,
    analysis_run_id: finding.analysis_run_id,
    location: finding.location,
    comment: finding.comment,
  });

  return (
    <>
      <AccordionActions>
      <Grid
      container
      direction="column" // Stack rows vertically
      justify="space-between"
      alignItems="stretch"
      style={{ paddingLeft: "8px", paddingRight: "8px" }}
      spacing={2} // Adds spacing between rows
    >
      <Grid container item direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={3}>
            <Tooltip title="Label this as a refugee boat" arrow>
              <span>
                <Button
                  className={styles.findingActionButton}
                  style={{ 
                    backgroundColor: labelColorMapping["refugee_boat"],
                    color: 'white', 
                    filter: finding.label==="refugee_boat" ? 'brightness(1)' : 'brightness(0.5)'
                  }}
                  variant="contained"
                  size="small"
                  disabled={false}
                  onClick={() => {
                      updateFindingDetails({
                        ...formData,
                        label: "refugee_boat",
                      });
                  }}
                >
                  Refugee Boat
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Confirm that this is a floating object" arrow>
              <span>
                <Button
                  className={styles.findingActionButton}
                  variant="contained"
                  style={{
                    backgroundColor: labelColorMapping["floating_object"],
                    color: 'white',
                    filter: finding.label==="floating_object" ? 'brightness(1)' : 'brightness(0.5)'
                  }}
                  size="small"
                  disabled={false}
                  onClick={() => {
                      updateFindingDetails({
                        ...formData,
                        label: "floating_object",
                      });
                  }}
                >
                  Floating Object
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Confirm that this is a boat" arrow>
              <span>
                <Button
                  className={styles.findingActionButton}
                  variant="contained"
                  style={{
                    backgroundColor: labelColorMapping["boat"],
                    color: 'white',
                    filter: finding.label==="boat" ? 'brightness(1)' : 'brightness(0.5)'
                  }}
                  size="small"
                  disabled={false}
                  onClick={() => {
                      updateFindingDetails({
                        ...formData,
                        label: "boat",
                      });
                  }}
                >
                  BOAT
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip
              title="Not a boat"
              arrow
            >
              <span>
                <Button
                  className={styles.findingActionButton}
                  size="small"
                  style={{
                    backgroundColor: labelColorMapping["not_boat"], 
                    color: 'white', 
                    filter: finding.label==="not_boat" ? 'brightness(1)' : 'brightness(0.5)'
                  }}
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    updateFindingDetails({
                      ...formData,
                    label:"not_boat"
                    })
                  }}
                >
                  NOT A BOAT
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        {finding.label && (
        <Grid container item direction="row" justify="space-between" alignItems="flex-start">
        <Grid item xs={6}>
        <FormControlLabel
      control={
        <Checkbox
          checked={finding.confirmed}
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you want to confirm this finding ?"
              )){
                updateFindingDetails({
                  ...formData,
                  confirmed: !finding.confirmed
              }
            );
            }
          }}
          color="primary" // You can use "secondary", "default", or "primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      }
      label="Confirm (Senior Experts Only)"
    />
        </Grid>
        <Grid item xs={6}>
            <Tooltip
              title="Remove label for this finding"
              arrow
            >
              <span>
                <Button
                  className={styles.findingActionButton}
                  aria-label="Remove Label"
                  size="small"
                  variant="outlined"
                  disabled={finding.label=== null}
                  onClick={() => {resetFinding()}}
                >
                  Remove Label
                </Button>
              </span>
            </Tooltip>
          </Grid>
    </Grid>)}
    </Grid>
      </AccordionActions>
    </>
  );
};
