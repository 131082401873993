import { Slider, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./tileFilterControl.module.css";

export default ({ mapRef }) => {
  const [open, setOpen] = useState(false);
  const [contrast, setContrast] = useState(1);
  const [brightness, setBrightness] = useState(1);
  const [saturate, setSaturate] = useState(1);

  useEffect(() => {
    const existingFilter = document.getElementById("tile-filter");
    existingFilter && existingFilter.remove();

    const style = document.createElement("style");
    style.setAttribute("id", "tile-filter");

    style.innerHTML = `
      .leaflet-tile {
        filter: contrast(${contrast}) brightness(${brightness}) saturate(${saturate});
      }
    `;

    document.head.appendChild(style);
  }, [contrast, brightness, saturate])

  const reset = () => {
    setContrast(1);
    setBrightness(1);
    setSaturate(1);
  }

  const Toggle = () => (
    <div
      className={styles.toggle}
      onClick={() => setOpen(true)}
    >
      Filter
    </div>
  )

  const sliders = () => (
    <div
      onMouseEnter={evt =>  mapRef.current.leafletElement.dragging.disable()}
      onMouseLeave={evt =>  mapRef.current.leafletElement.dragging.enable()}
    >
      <span>Contrast</span>
      <Slider
        defaultValue={contrast} value={contrast}
        step={0.1} min={0.1} max={2}
        onChange={(evt, val) => setContrast(val)}
      />

      <span>Brightness</span>
      <Slider
        defaultValue={brightness} value={brightness}
        step={0.1} min={0.1} max={2}
        onChange={(evt, val) => setBrightness(val)}
      />

      <span>Saturation</span>
      <Slider
        defaultValue={saturate} value={saturate}
        step={0.1} min={0.1} max={2}
        onChange={(evt, val) => setSaturate(val)}
      />

      <Button variant="contained" onClick={reset}>Reset</Button>
      <Button variant="contained" onClick={() => setOpen(false)}>Close</Button>
    </div>
  )

  return (
    <div className={styles.control}>
      {open ? sliders() : <Toggle />}
    </div>
  )
}
