import React, { useRef, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import scrollIntoView from 'scroll-into-view-if-needed';
import { Drawer, IconButton, Button, Tooltip, Select, MenuItem } from "@material-ui/core";
import { Close as CloseIcon, Sort as SortIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@material-ui/icons";
import FindingAccordion from "./findingAccordion/findingAccordion";
import { SORT_METHODS } from "./../../models/AnalysisRuns"
import styles from "./findingsPanel.module.css";

export default ({ selectedFinding }) => {

  const displayedFindings = useStoreState((state) => state.AnalysisRuns.displayedFindings);

  const setCurrentSorting = useStoreActions(
    (actions) => actions.AnalysisRuns.setCurrentSorting
  );

  const currentSorting = useStoreState(
    (state) => state.AnalysisRuns.currentSorting
  );

  const setShowLabeled = useStoreActions(
    (actions) => actions.AnalysisRuns.setShowLabeled
  );

  const showLabeled = useStoreState(
    (state) => state.AnalysisRuns.showLabeled
  );

  const findingsPanelVisible = useStoreState(
    (state) => state.AnalysisRuns.findingsPanelVisible
  );
  const toggleFindingsPanel = useStoreActions(
    (actions) => actions.AnalysisRuns.toggleFindingsPanel
  );

  const findingRef = useRef(null);

  const isSelected = (finding) => (
    selectedFinding && selectedFinding.finding_id === finding.finding_id
  )

  useEffect(() => {
    findingRef && findingRef.current && scrollIntoView(findingRef.current, { behavior: 'smooth', scrollMode: 'if-needed' })
  }, [selectedFinding])

  const handleSortChange = (event) => {
    const sortingType = event.target.value;
    setCurrentSorting(sortingType);
  };

  const handleShowLabeledClick = (event) => {
    setShowLabeled(!showLabeled);
  };

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={findingsPanelVisible}
      classes={{ paper: styles.paper }}
    >
      <div className={styles.drawerHeader}>

      <Tooltip title="Sort by" arrow>
        <Select
          value={currentSorting}
          onChange={handleSortChange}
          displayEmpty
          className={styles.leftButton}
          IconComponent={SortIcon}
        >
          <MenuItem value="" disabled>
            Select Sorting Option
          </MenuItem>
          <MenuItem value={SORT_METHODS.CONFIDENCE}>Confidence</MenuItem>
          <MenuItem value={SORT_METHODS.LABEL}>Label</MenuItem>
        </Select>
      </Tooltip>


      <Tooltip title={showLabeled ? "Hide Labeled": "Show Labeled"} arrow>
        <Button
        className={styles.leftButton}
        onClick={handleShowLabeledClick}
        startIcon={showLabeled ? <VisibilityIcon /> : <VisibilityOffIcon />}
        >
        </Button>
      </Tooltip>

      <IconButton
        onClick={() => toggleFindingsPanel(false)}
        className={styles.rightButton}
      >
        <CloseIcon />
      </IconButton>
    </div>

      <div className={styles.findings} style={{ paddingBottom: '63px'}}>
        {displayedFindings.map((finding) => (
          <div key={finding.finding_id} ref={isSelected(finding) ? findingRef : null}>
            <FindingAccordion
              finding={finding}
              highlighted={isSelected(finding)}
              />
          </div>
        ))}
      </div>
    </Drawer>
  );
};
