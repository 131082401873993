import styles from "./index.module.css";
import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CurrentAnalysisRun from '../currentAnalysisRun';
import { useParams } from 'react-router-dom';

export default () => {
  let { analysis_run_id } = useParams();
  const currentAnalysisRun = useStoreState((state) => state.AnalysisRuns.currentAnalysisRun);
  const allAnalysisRuns = useStoreState((state) => state.AnalysisRuns.allAnalysisRuns);
  const setCurrentAnalysisRunById = useStoreActions((actions) => actions.AnalysisRuns.setCurrentAnalysisRunById);
  const getAllAnalysisRuns = useStoreActions((actions) => actions.AnalysisRuns.getAllAnalysisRuns);

  useEffect(() => { getAllAnalysisRuns() }, [])
  useEffect(() => { setCurrentAnalysisRunById(analysis_run_id); }, [analysis_run_id, allAnalysisRuns])

  const AnalysisRun = () => (
    currentAnalysisRun ?
      <CurrentAnalysisRun currentAnalysisRun={currentAnalysisRun}/> :
        <div className={styles.spinner}></div>
  )

  return AnalysisRun()
};
