import React, { useRef } from "react";
import { useStoreState } from "easy-peasy";
import FindingsMap from "./findingsMap";
import FindingsController from "./findingsController";
import FindingsPanel from "./findingsPanel";
import Grid from '@material-ui/core/Grid';

export default ({ currentAnalysisRun }) => {
  const selectedFinding = useStoreState((state) => state.AnalysisRuns.selectedFinding);
  const showLabeled = useStoreState((state) => state.AnalysisRuns.showLabeled);
  const displayedFindings = useStoreState((state) => state.AnalysisRuns.displayedFindings);
  const mapRef = useRef();

  return (
    <>
      <FindingsMap
        mapRef={mapRef}
        selectedFinding={selectedFinding}
      />

      <FindingsPanel
        selectedFinding={selectedFinding}
        showLabeled={showLabeled}
      />

      <FindingsController
        mapRef={mapRef}
        currentAnalysisRun={currentAnalysisRun}
      />
    </>
  );
};
