import React from 'react';
import { Button } from '@material-ui/core';
import styles from './index.module.css';
import axios from 'axios';

export default() => {
  const logout = async() => {
    try {
      await axios.post(
        `${process.env.BACKEND_BASE_URL}/auth/logout/`, {}, { Authorization: `Token ${localStorage.getItem('token')}` }
      );

      localStorage.clear();
      window.location.replace('/');
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = e => {
    e.preventDefault();
    logout();
  };

  return (
    <div className={styles.container}>
      <h1>Are you sure you want to logout?</h1>
      <Button variant="contained" value='Logout' onClick={handleLogout}>Logout</Button>
    </div>
  );
};
