import { thunk, action } from "easy-peasy";
import axios from "axios";
import { headers } from "./headers";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export const findings = {
  showSuccessMessage: "",

  setShowSuccessMessage: action((state, payload) => {
    state.showSuccessMessage = payload;
  }),

  // Serialize and return diff between old and new state in order to PATCH properly
  serialize: thunk((actions, payload, helpers) => {
    const getDifference = (a, b) =>
      Object.entries(b)
        .filter(([key, val]) => a[key] !== val && key in a)
        .reduce((a, [key, v]) => ({ ...a, [key]: v }), {});

    const finding = helpers
      .getStoreState()
      .AnalysisRuns.currentAnalysisRun.unfilteredFindings.find(
        (f) => f.finding_id === payload.finding_id
      );

    return getDifference(finding, payload);
  }),

  updateFindingDetails: thunk(async (actions, payload, helpers) => {
    actions.setShowSuccessMessage("Pushing changes to backend...");
    const requestPayload = actions.serialize(payload);

    try {
      await axios.patch(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/${payload.analysis_run_id}/findings/${payload.finding_id}`,
        requestPayload,
        headers()
      );
      helpers.getStoreActions().AnalysisRuns.setFindingDetails({
        ...requestPayload,
        finding_id: payload.finding_id,
      });
      actions.setShowSuccessMessage("Update successful!");
    } catch (error) {
      console.log(error.message);
    }
  }),
};
