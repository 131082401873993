import { thunk, action } from "easy-peasy";
import axios from "axios";
import { headers } from "./headers";

export const NewFindings = {
  currentNewFinding: null,
  currentNewFindingGeometry: null,
  manualFindingMode: false,

  showSuccessMessage: "",

  enterManualFindingMode: action((state, payload) => {
    state.manualFindingMode = true;
  }),

  leaveManualFindingMode: action((state, payload) => {
    state.manualFindingMode = false;
  }),

  setShowSuccessMessage: action((state, payload) => {
    state.showSuccessMessage = payload;
  }),

  updateNewFindingGeometry: action((state, payload) => {
    state.currentNewFindingGeometry = payload;
  }),

  setCurrentNewFinding: action((state, payload) => {
    state.currentNewFinding = payload;
  }),

  addNewFinding: thunk(async (actions, payload, helpers) => {
    helpers.getStoreActions().AnalysisRuns.addManualFinding(payload);
    actions.setCurrentNewFinding(payload);
    actions.leaveManualFindingMode();
    actions.updateNewFindingGeometry(null);
  }),

  removeNewFinding: thunk(async (actions, payload, helpers) => {
    helpers.getStoreActions().AnalysisRuns.removeManualFinding(payload);
    actions.leaveManualFindingMode();
    actions.setCurrentNewFinding(null);
    actions.updateNewFindingGeometry(null);
  }),

  postManualFinding: thunk(async (actions, payload, helpers) => {
    actions.setShowSuccessMessage("Creating manual finding in backend...");

    try {
      const data = await axios.post(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/${payload.analysis_run_id}/findings/`,
        payload,
        headers()
      );
      helpers.getStoreActions().AnalysisRuns.setDetailsOfDraftFinding({
        finding_temp_id: payload.finding_temp_id,
        finding_id: data.data.finding_id,
        draft: false,
      });
      actions.setShowSuccessMessage("Successfully sent finding to backend!");
      actions.leaveManualFindingMode();
      actions.setCurrentNewFinding(null);
      actions.updateNewFindingGeometry(null);
    } catch (error) {
      console.log(error.message);
    }
  }),
};
