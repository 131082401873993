import React from 'react';
import useTokenCheck from '../../hooks/useTokenCheck';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import { Toolbar, AppBar, Grid } from '@material-ui/core';
import Icon from "@material-ui/icons/Language";

export default() => {
  const isLoggedIn = useTokenCheck();

  return (
    <AppBar className={styles.appBar} position="sticky">
      <Toolbar>
        <Grid container>
        </Grid>
        <Grid container justify="center">
          <Grid item><Link to="/"><Button className={styles.homeLink}><Icon />satelliteSearch</Button></Link></Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item><Link to="/about"><Button>About</Button></Link></Grid>
          {isLoggedIn && <Grid item><Link to='/logout'><Button>Logout</Button></Link></Grid>}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
