import bbox from "@turf/bbox";
import featureCollection from "turf-featurecollection";

const findingsCollection = (findings) => {
  let newCollection = featureCollection();
  newCollection.features = [];

  // Collect all features and return center of entire feature collection
  findings.forEach((finding) =>
    finding.location.features.forEach((feature) => {
      newCollection.features.push(feature);
    })
  );

  return newCollection;
};

export  const calcBounds = (findings) => {
  return bbox(findingsCollection(findings));
};

export default findingsCollection;