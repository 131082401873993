import React from 'react';
import Login from '../Login';
import useTokenCheck from '../../hooks/useTokenCheck';
import LimitedContaner from './LimitedContainer';

export default ({ children, auth = false, withoutContainer = false }) => {
  const isLoggedIn = useTokenCheck();

  const Content = () => (
    withoutContainer ? <>{children}</> : <LimitedContaner>{children}</LimitedContaner>
  )

  return (
    <>
      {(auth && isLoggedIn) || !auth ? (
        <Content />
      ) : (
        <LimitedContaner><Login /></LimitedContaner>
      )}
    </>
  )
};
