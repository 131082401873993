import { calcBounds } from "./calcBounds";

const createGpx = (findings) => {

  const getCenter = (poly) => {
    return [
      0.5 * (poly[2] + poly[0]),
      0.5 * (poly[3] + poly[1])
    ];
  }
  
  const gpxHeader = '<?xml version="1.0" encoding="UTF-8"?>\n<gpx version="1.1" creator="SpaceEye" xmlns="http://www.topografix.com/GPX/1/1">\n';

  const waypoints = findings.map((finding) => {
    const bounds = calcBounds([finding]);
    const calculatedCenter = getCenter(bounds);
  
    let waypoint = `
      <wpt lat="${calculatedCenter[1]}" lon="${calculatedCenter[0]}">
        <name>Space Eye ${finding.sat_image_captured_at}</name>
        <sym>Symbol-Glow-Small-Black</sym>
        <time>${finding.sat_image_captured_at}</time>
        <type>WPT</type>
        <desc>Confidence: ${(finding.confidence*100).toFixed(1)}%, ID: ${finding.finding_id}, Comment: ${finding.comment}</desc>
      </wpt>
    `;
  
    return waypoint;
  }).join("");
  
  

  const gpxFooter = "</gpx>";
  const gpxContent = gpxHeader + waypoints + gpxFooter;
  return gpxContent;
};

export default createGpx;
