import { action, thunk, computed } from "easy-peasy";
import axios from "axios";
import { headers } from "./headers";

export const SORT_METHODS = {
  CONFIDENCE: 'confidence',
  LABEL: 'label'
};

export const AnalysisRuns = {
  currentAnalysisRun: null,
  selectedFinding: null,
  allAnalysisRuns: [],
  showLabeled: false,
  currentSorting: SORT_METHODS.CONFIDENCE,
  currentConfidenceThreshold: "0.3",

  setShowLabeled: action((state, payload) => {
    state.showLabeled = payload;
  }),

  toggleFindingsPanel: action((state, payload) => {
    state.findingsPanelVisible = payload;
  }),

  setCurrentSorting: action((state, payload) => {
    if (Object.values(SORT_METHODS).includes(payload)) {
      state.currentSorting = payload;
    }

  }),

  setCurrentAnalysisRun: action((state, payload) => {
    state.currentAnalysisRun = payload;
  }),

  setAllAnalysisRuns: action((state, payload) => {
    state.allAnalysisRuns = payload;
  }),

  addManualFinding: action((state, payload) => {
    state.currentAnalysisRun.unfilteredFindings.push(payload);
  }),

  removeManualFinding: action((state, payload) => {
    const findings = state.currentAnalysisRun.unfilteredFindings.filter(
      (p) => p.finding_id !== payload
    );
    state.currentAnalysisRun.unfilteredFindings = findings;
    state.selectedFinding = null;
  }),

  // Select passed finding if nothing else selected
  // Unselect passed finding if already selected
  // Select passed finding if prior conditions do not match
  setSelectedFinding: action((state, payload) => {
    if (state.selectedFinding === null) {
      state.selectedFinding = payload;
    }
    else if (state.selectedFinding.finding_id === payload.finding_id) {
      state.selectedFinding = null;
    }
    else {
      state.selectedFinding = payload;
    }
  }),

  setFindingDetails: action((state, payload) => {
    let findingIndex = state.currentAnalysisRun.unfilteredFindings.findIndex(
      (f) => f.finding_id === payload.finding_id
    );
    for (const [key, value] of Object.entries(payload)) {
      state.currentAnalysisRun.unfilteredFindings[findingIndex][key] = value;
    }
  }),

  setDetailsOfDraftFinding: action((state, payload) => {
    let findingIndex = state.currentAnalysisRun.unfilteredFindings.findIndex(
      (f) => f.finding_temp_id === payload.finding_temp_id
    );
    for (const [key, value] of Object.entries(payload)) {
      state.currentAnalysisRun.unfilteredFindings[findingIndex][key] = value;
    }
  }),

  displayedFindings: computed((state) => {

    const { currentAnalysisRun, currentSorting, showLabeled} = state;

    if (!currentAnalysisRun) {
      console.log("no currentAnalysisRun");
      return {};
    }

    // Hide unlabeled findings (optional)
    
    const filteredByLabel = showLabeled 
    ? currentAnalysisRun.unfilteredFindings
     : currentAnalysisRun.unfilteredFindings.filter((f) => f.label === null);

    // Apply confidence threshold

    const filtered = filteredByLabel.filter( (f) => f.confidence > state.currentConfidenceThreshold);
    
    // Sort acording to selected method

    let sorted;

    switch (currentSorting) {  

      default:
        sorted = filtered;
        break;

      case SORT_METHODS.LABEL:

        const priority = {
          "refugee_boat": 1,
          "floating_object": 2,
          "boat": 3,
          "not_boat": 4,
          null: 5
        };
  
        sorted = filtered.sort((a, b) => {
          return priority[a.label] - priority[b.label];
        });

        break;

      case SORT_METHODS.CONFIDENCE:
        sorted = [...filtered].sort((a, b) =>
          a.confidence > b.confidence ? -1 : 1
        );
        break
    }
    return sorted;
  }),

  setCurrentConfidenceThreshold: action((state, payload) => {
    state.currentConfidenceThreshold = payload;
  }),

  setCurrentAnalysisRunById: thunk(async (actions, payload, helpers) => {
    try {
      const AnalysisRun = helpers
        .getState()
        .allAnalysisRuns.find((p) => p.analysis_run_id === payload);

      if (AnalysisRun !== undefined) {
        // Fetch findings for analysis run
        const { data } = await axios.get(
          `${process.env.BACKEND_BASE_URL}/analysis_runs/${AnalysisRun.analysis_run_id}/findings/`,
          headers()
        );

        data.forEach((finding) => {
          // Set draft =  false for findings fetched from backend
          finding.draft = false;
        });

        // Filter by confidence threshold
        const currentConfidenceThreshold = helpers.getState()
          .currentConfidenceThreshold;
        const filtered_data = data.filter(
          (f) => f.confidence > currentConfidenceThreshold
        );

        actions.setCurrentAnalysisRun({
          ...AnalysisRun,
          unfilteredFindings: data,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  }),

  getAllAnalysisRuns: thunk(async (actions, payload, helpers) => {
    try {
      const { data } = await axios.get(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/`,
        headers()
      );
      console.log(data);
      actions.setAllAnalysisRuns(data);
    } catch (error) {
      console.log(error.message);
    }
  }),

  createAnalysisRun: thunk(async (actions, payload) => {
    try {
      const { data } = await axios.post(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/`,
        payload,
        headers()
      );
      actions.setCurrentAnalysisRun(data);
    } catch (error) {
      console.log(error.message);
    }
  }),

  deleteAnalysisRun: thunk(async (actions, payload, helpers) => {
    try {
      await axios.delete(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/${payload}`,
        headers()
      );

      const analysisRuns = helpers
        .getState()
        .allAnalysisRuns.filter((p) => p.analysis_run_id !== payload);
      actions.setAllAnalysisRuns(analysisRuns);
    } catch (error) {
      console.log(error.message);
    }
  }),
};
