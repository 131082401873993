import React, { memo } from "react";
import { useStoreState } from "easy-peasy";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

export default memo(() => {
  const aisData = useStoreState((state) => state.aisData.features);

  const triangle = (rotation, color) => {
    return L.divIcon({
      html: `
        <div class="triangle-container" style="transform: rotate(${rotation}deg)">
          <svg height="20" width="20">
            <polygon points="10,0 3,20 17,20" style="stroke: white; fill:${color}; opacity:0.6" />
          </svg>
        </div>
      `,
      className: "svg-icon",
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });
  }

  const randomColor = () => (
    '#'+Math.floor(Math.random()*16777215).toString(16)
  )

  const ShipMarker = ({ feature, color }) => (
    <Marker
      position={[feature.latitude, feature.longitude]}
      icon={triangle(feature.heading, color)}
    >
      <Popup>
        <h3>{feature.vessel_name}</h3>
        <b>MMSI: </b><span>{feature.mmsi}</span><br/>
        <b>Type: </b><span>{feature.vessel_type}</span><br/>
        <b>Status: </b><span>{feature.nav_status}</span><br/>
        <b>Time: </b><span>{feature.dt_pos_utc}</span><br/>
        <b>Flag country: </b><span>{feature.flag_country}</span><br/>
        <b>Destination: </b><span>{feature.destination}</span><br/>
      </Popup>
    </Marker>
  )

  const Ships = () => {
    // assign unique random color to each ship

    let mmsi;
    let color;


    return aisData.sort((a, b) => a.mmsi < b.mmsi).map((feature) => {
      if (feature.mmsi !== mmsi) {
        color = randomColor();
        mmsi = feature.mmsi;
      };

      return <ShipMarker
        key={`${feature.dt_pos_utc} ${feature.mmsi}`}
        feature={feature}
        color={color}
      />
    })
  }

  return (
    <MarkerClusterGroup maxClusterRadius={30} disableClusteringAtZoom={13}>
      <Ships />
    </MarkerClusterGroup>
  )
})
